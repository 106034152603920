import React from "react";
import PropTypes from "prop-types";
import { getUserInfo } from "../../utils/auth";
import { Link } from "gatsby";
import { FaUser } from "react-icons/fa/";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  handleMenuClick = e => {
    // eslint-disable-next-line react/prop-types
    this.props.logout();
  };

  handleIn = () => {
    if (!this.state.menuOpen) {
      this.setState({ menuOpen: true });
    }
  };

  handleOut = () => {
    if (this.state.menuOpen) {
      this.setState({ menuOpen: false });
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { isAuthenticated, login, theme } = this.props;

    const profile = typeof window !== "undefined" ? getUserInfo() : "";

    return (
      <li className="item" onMouseEnter={this.handleIn} onMouseLeave={this.handleOut}>
        {!isAuthenticated ? (
          <Link className="link-login" to="" onClick={login}>
            Log In / Register
            <FaUser />
          </Link>
        ) : (
          <div className="user-dropdown">
            <button>
              {profile.nickname}
              <FaUser />
            </button>
            {this.state.menuOpen && (
              <ul className="menulist">
                <li>
                  <Link className="subItem" to="" onClick={this.handleMenuClick}>
                    Logout
                  </Link>
                </li>
              </ul>
            )}
          </div>
        )}
        <style jsx>{`
          .item {
            display: flex;
            // eslint-disable-next-line react/prop-types
            font-size: ${theme.font.size.xs};
            color: green;
          }
          .user-dropdown {
            display: block;
            position: relative;

            > ul {
              margin: 0;
              top: 40px;
              background: ${theme.color.neutral.white};
              box-shadow: ${theme.space.xs};
              list-style: none;
              visibility: true;
              opacity: 0;
              padding: 0.5rem 0;

              position: absolute;
              padding: ${theme.space.sx};
              border-radius: ${theme.size.radius.small};
              border-top-right-radius: 0;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              opacity: 1;
              > li {
                width: 100%;
              }
              > li > a {
                display: block;
                padding: ${theme.space.inset.xs};
                align-items: center;
                display: block;
                color: ${theme.text.color.primary};
                font-size: ${theme.font.size.xs};

                :global(a:hover) {
                  color: ${theme.color.brand.primary};
                  background: color(white alpha(-60%));
                }
                :global(button:hover) {
                  color: ${theme.color.brand.primary};
                  background: color(white alpha(-60%));
                }
              }
            }

            button {
              border: none;
              padding-right: 0px;
              color: green;
              font-weight: bold;
              font-size: ${theme.font.size.xs};
              font-family: ${theme.font.family.target};
              background: transparent;
              transition: all ${theme.time.duration.default};
              padding: ${theme.space.inset.s};
              display: flex;
              align-items: center;
              border-radius: ${theme.size.radius.small};

              :global(svg) {
                margin: 0 ${theme.space.inset.xs} 0 0;
                opacity: 0.3;
              }
              &:hover {
                color: ${theme.color.brand.primary};
                background: color(white alpha(-60%));
              }
            }

            .item,
            .showItem {
              background: transparent;
              transition: all ${theme.time.duration.default};
              display: flex;
              align-items: center;
              display: block;

              :global(a) {
                padding: ${theme.space.inset.s};
                font-size: ${theme.font.size.xs};
                display: flex;
                align-items: center;
              }

              :global(svg) {
                margin: 0 ${theme.space.inset.xs} 0 0;
                opacity: 0.3;
              }
            }

            :global(.itemList .hideItem) {
              display: none;
            }
          }

          @from-width desktop {
            .user-dropdown {
              :global(ul) {
                background: ${theme.color.neutral.white};
              }
              :global(.homepage):not(.fixed) & :global(ul) {
                background: color(white alpha(-60%));
              }
            }

            .item {
              :global(a) {
                color: ${theme.text.color.primary};
                padding: ${theme.space.inset.s};
                transition: all ${theme.time.duration.default};
                border-radius: ${theme.size.radius.small};
              }
              :global(.homepage):not(.fixed) & :global(a) {
                color: ${theme.color.neutral.white};
              }

              :global(a:hover) {
                color: ${theme.color.brand.primary};
                background: color(white alpha(-60%));
              }

              :global(svg) {
                transition: all ${theme.time.duration.default};
              }

              &:hover :global(svg) {
                fill: ${theme.color.brand.primary};
                opacity: 1;

                :global(.hero) & :global(svg) {
                  fill: green;
                }
              }
            }
            :global(.homepage):not(.fixed) & :global(a.link-login) {
              /*color: #73d13d;*/
              color: green;
            }
            :global(a.link-login) {
              /*color: #73d13d;*/
              color: green !important;
            }
          }
        `}</style>
      </li>
    );
  }
}
export default Login;
