import axios from "axios";
import qs from "qs";
import { getAccessToken } from "./auth";

export function getScreenWidth() {
  if (typeof window !== `undefined`) {
    return window.innerWidth;
  }
}

export function isWideScreen() {
  if (typeof window !== `undefined`) {
    const windowWidth = window.innerWidth;
    const mediaQueryL = 1024;

    return windowWidth >= mediaQueryL;
  }
}

export function timeoutThrottlerHandler(timeouts, name, delay, handler) {
  if (!timeouts[name]) {
    timeouts[name] = setTimeout(() => {
      timeouts[name] = null;
      handler();
    }, delay);
  }
}

export function useComponentDidMount(onMounted) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);

    onMounted();
  }, [mounted]);
}

// check if user subscribed
export async function checkSubscribed(email) {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${process.env.STRIPE_RESTRICTED_KEY}`
    },
    params: {
      email: email
    }
  };

  try {
    const res = await axios.get(`https://api.stripe.com/v1/customers`, config);
    const data = res.data.data;
    if (data.length > 0) {
      const subscriptions = data[0].subscriptions.data;
      return subscriptions.map(sub => sub.status === "active").length;
    }
  } catch (err) {
    console.log(err);
  }
  return 0;
}

// check if user subscribed to 'NFL' product
export async function checkSubscribedToNFL(email, cb) {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${process.env.STRIPE_RESTRICTED_KEY}`
    },
    params: {
      email: email,
      limit: 100
    }
  };

  try {
    var len = 0;
    const res = await axios.get(`https://api.stripe.com/v1/customers`, config);
    const data = res.data.data;
    if (data.length > 0) {
      var i;
      for (i = 0; i < data.length; i++) {
        const sub = data[i].subscriptions.data;
        sub.forEach(element => {
          if (
            element.status === "active" &&
            element.plan.product === process.env.STRIPE_NFL_PRODUCT_ID
          ) {
            len = 1;
          }
        });
      }
    }
  } catch (err) {
    // console.log("ERRRRROR");
    console.log(err);
  }
  // console.log("No subs matched plan");
  return len;
}

export async function getGoogleSheetData() {
  const API_URL =
    "https://sheets.googleapis.com/v4/spreadsheets/1nGItayQZUyFVCycaAAOJ0ZtSisg_Z8j58yt377Ip2dc?ranges=I4:O1000&fields=sheets&key=" +
    process.env.GOOGLESHEET_API_KEY;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  };
  const res = await axios.get(API_URL, config);
  return res;
}

export async function checkCorePlayImageExists(url) {
  try {
    const res = await axios.head(url);
    console.log(res);
    const statusCode = res.status;
    if (statusCode == 200) {
      // const lastModified = res.headers["last-modified"];
      // const diff = moment(lastModified).fromNow();
      // return diff;
      return 1;
    }
  } catch (err) {
    console.log(err);
  }
  // return null;
  return 0;
}

export async function checkPurchasedCorePlays(email, strDate) {
  // TODO: on login, email is coming in as "undefined"
  const stringDate = strDate.replace(/-/g, "");
  console.log("checkPurchasedCorePlays");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
      // Authorization: `Bearer ${process.env.STRIPE_RESTRICTED_KEY}`
    }
  };
  const url =
    "https://lblkxvwpx5.execute-api.us-east-1.amazonaws.com/Prod/purchase/" +
    email +
    "/" +
    stringDate;
  console.log(url);
  try {
    const res = await axios.get(url, config);
    console.log(res);
    const exists = res.data.exists;
    console.log(exists);
    if (exists) {
      console.log("it exists");
      return 1;
    }
  } catch (err) {
    console.log(err);
  }
  console.log("it doesnt exists");
  return 0;
}
