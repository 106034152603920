module.exports = [{
      plugin: require('/codebuild/output/src992362443/src/thefantasybros/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src992362443/src/thefantasybros/src/layouts/index.js"},
    },{
      plugin: require('/codebuild/output/src992362443/src/thefantasybros/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src992362443/src/thefantasybros/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src992362443/src/thefantasybros/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-135890698-2"},
    },{
      plugin: require('/codebuild/output/src992362443/src/thefantasybros/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://384ba449450b48c486f0a6a802d6fd72@sentry.io/1523373","environment":"stage","enabled":true},
    },{
      plugin: require('/codebuild/output/src992362443/src/thefantasybros/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
