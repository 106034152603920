// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src992362443/src/thefantasybros/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-template-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-category-template-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-author-template-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/templates/AuthorTemplate.js" /* webpackChunkName: "component---src-templates-author-template-js" */),
  "component---src-templates-page-template-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-0-callback-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/auth0_callback.js" /* webpackChunkName: "component---src-pages-auth-0-callback-js" */),
  "component---src-pages-blog-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membership-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-nba-core-plays-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/nba/core-plays.js" /* webpackChunkName: "component---src-pages-nba-core-plays-js" */),
  "component---src-pages-nfl-core-plays-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/nfl/core-plays.js" /* webpackChunkName: "component---src-pages-nfl-core-plays-js" */),
  "component---src-pages-nfl-optimizer-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/nfl/optimizer.js" /* webpackChunkName: "component---src-pages-nfl-optimizer-js" */),
  "component---src-pages-nfl-ownership-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/nfl/ownership.js" /* webpackChunkName: "component---src-pages-nfl-ownership-js" */),
  "component---src-pages-nfl-season-long-questions-and-answers-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/nfl/season-long-questions-and-answers.js" /* webpackChunkName: "component---src-pages-nfl-season-long-questions-and-answers-js" */),
  "component---src-pages-nfl-season-long-rankings-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/nfl/season-long-rankings.js" /* webpackChunkName: "component---src-pages-nfl-season-long-rankings-js" */),
  "component---src-pages-nfl-season-long-waiver-wire-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/nfl/season-long-waiver-wire.js" /* webpackChunkName: "component---src-pages-nfl-season-long-waiver-wire-js" */),
  "component---src-pages-nfl-sports-betting-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/nfl/sports-betting.js" /* webpackChunkName: "component---src-pages-nfl-sports-betting-js" */),
  "component---src-pages-tag-js": () => import("/codebuild/output/src992362443/src/thefantasybros/src/pages/tag.js" /* webpackChunkName: "component---src-pages-tag-js" */)
}

