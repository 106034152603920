import { FaTag } from "react-icons/fa/";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import List from "../components/List";

const CategoryTemplate = props => {
  const {
    pageContext: { category },
    data: {
      posts: { edges: posts = [] },
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;
  const totalCount = posts.length;
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline theme={theme}>
                <span>Posts in category</span> <FaTag />
                {category}
              </Headline>
              <p className="meta">
                There {totalCount > 1 ? "are" : "is"} <strong>{totalCount}</strong> post
                {totalCount > 1 ? "s" : ""} in the category.
              </p>
              <List edges={posts} theme={theme} />
            </header>
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} />
    </React.Fragment>
  );
};

CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default CategoryTemplate;

// eslint-disable-next-line no-undef
export const categoryQuery = graphql`
  query PostsByCategory($category: String) {
    posts: allPrismicGenericPost(
      limit: 1000
      sort: { fields: first_publication_date, order: DESC }
      filter: { tags: { in: [$category] } }
    ) {
      edges {
        node {
          id
          first_publication_date
          last_publication_date
          slugs
          uid
          tags
          data {
            author_link {
              id
              slug
              document {
                data {
                  author_name {
                    html
                    text
                  }
                }
              }
            }
            title {
              html
              text
            }
            content {
              html
              text
              raw {
                type
                text
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
